import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

import { ApiConnectionService } from '../services/Api-Connection.service';

@Injectable()
export class ProductTypesResolver implements Resolve<any[]> {

    constructor(
        private apiConnection: ApiConnectionService
    ) { }

    resolve(): Observable<any> {
        return this.apiConnection.ServerRequest(`/api/getalltypes`, 'GET', null)
            .catch((err) => {
                return Observable.of(err);
            });
    }
}
