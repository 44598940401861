import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

import { ApiConnectionService } from '../services/Api-Connection.service';

@Injectable()
export class RequestsResolver implements Resolve<any> {

    constructor(
        private apiConnection: ApiConnectionService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.apiConnection.ServerRequest(`/api/PrintRequest`, 'GET', null)
            .catch((err) => {
                return Observable.of(err);
            });
    }
}
