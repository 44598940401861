import { Directive, OnInit, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { JwtHelper } from './jwt-helper';
import { Role } from '../enums/Role.enum';

@Directive({
    selector: '[appCanAccess]'
})
export class CanAccessDirective implements OnInit {

    @Input('appCanAccess') appCanAccess: string | string[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private jwt: JwtHelper
    ) { }

    ngOnInit(): void {
        this.applyPermission();
    }

    private applyPermission(): void {

        const decodedToken = this.jwt.decodeToken();

        const allRoles: number[] = decodedToken.Roles;

        switch (this.appCanAccess) {
            case Role[Role.AddRequestPage]:
                const addIndex = allRoles.findIndex(a => a === Role.AddRequestPage);
                if (addIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
            case Role[Role.ApproveRequestButton]:
                const approveBtnIndex = allRoles.findIndex(a => a === Role.ApproveRequestButton);
                if (approveBtnIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
            case Role[Role.PrintingPage]:
                const printIndex = allRoles.findIndex(a => a === Role.PrintingPage);
                if (printIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
            case Role[Role.ReprintButton]:
                const reprintBtnIndex = allRoles.findIndex(a => a === Role.ReprintButton);
                if (reprintBtnIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
            case Role[Role.StoreButton]:
                const storeIndex = allRoles.findIndex(a => a === Role.StoreButton);
                if (storeIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
            case Role[Role.DispenceButton]:
                const dispenceIndex = allRoles.findIndex(a => a === Role.DispenceButton);
                if (dispenceIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
            case Role[Role.ReturnStickersPage]:
                const returnIndex = allRoles.findIndex(a => a === Role.ReturnStickersPage);
                if (returnIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
            case Role[Role.UnlockButton]:
                const unlockIndex = allRoles.findIndex(a => a === Role.UnlockButton);
                if (unlockIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
            case Role[Role.AddGroupButton]:
                const addGroupIndex = allRoles.findIndex(a => a === Role.AddGroupButton);
                if (addGroupIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
            case Role[Role.DeleteGroupButton]:
                const deleteGroupIndex = allRoles.findIndex(a => a === Role.DeleteGroupButton);
                if (deleteGroupIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
            case Role[Role.ReportsPages]:
                const reportsIndex = allRoles.findIndex(a => a === Role.ReportsPages);
                if (reportsIndex !== -1) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
                break;
        }
    }
}
